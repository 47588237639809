import React from 'react';
import { Box, Heading, Text } from '@chakra-ui/react';

import WithAnimation from '@/components/Common/WithAnimation';

import { THE_BRIDE, AYAT } from '@/constants';
import { TEXT_PROPS } from '@/constants/default-props';
import { BACKGROUND } from '@/constants/assets';

import useInvitation from '@/hooks/useInvitation';

// const TEXT_SHADOW = 'rgba(12,12,12,.8)';

function LeftContent() {
  const isInvitation = useInvitation();

  return (
    <Box
      bgColor="bgPrimary"
      width="calc(100% - 500px)"
      height="100%"
      pos="fixed"
      bgImage={BACKGROUND.desktop}
      bgSize="cover"
      borderRight="8px solid"
      borderRightColor="blackAlpha.400"
    >
      <Box bgColor="blackAlpha.400" h="100%" padding="42px">
        <WithAnimation>
          <Text
            {...TEXT_PROPS.sub_title}
            fontWeight="bold"
            textAlign="left"
            // textShadow={`1px 1px 4px ${TEXT_SHADOW}`}
          >
            {isInvitation ? 'THE WEDDING OF' : 'WEDDING ANNOUNCEMENT'}
          </Text>
        </WithAnimation>
        <WithAnimation>
          <Heading
            {...TEXT_PROPS.title}
            textAlign="left"
            fontSize="8xl"
            lineHeight="1"
            mt="32px"
            // textShadow={`1px 1px 6px ${TEXT_SHADOW}`}
            dangerouslySetInnerHTML={{ __html: THE_BRIDE }}
          />
        </WithAnimation>
        <WithAnimation>
          <Text
            {...TEXT_PROPS.body}
            fontStyle="italic"
            textAlign="left"
            fontSize="xl"
            // textShadow={`1px 1px 4px ${TEXT_SHADOW}`}
          >
            {AYAT.text} <br />- {AYAT.verse}
          </Text>
        </WithAnimation>
      </Box>
    </Box>
  );
}

export default LeftContent;
